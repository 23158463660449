import React from "react";
import FintechsLogos from "./FintechsLogos";

const Hero =()=>  {
    return (
        <section className='hero'>
            <hgroup>
                <h1>
                    {'All you need for ISO20022 adoption'}
                </h1>
                <h2>
                    {'Tools to Build, Validate, and Translate your SWIFT MT, ISO20022, SEPA,\nCBPR+, TARGET2, MEPS+, Instant payment, FedNow and other local schemes'}
                </h2>
            </hgroup>
            <FintechsLogos />
        </section>
    );
};

export default Hero;
