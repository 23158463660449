import React, {Suspense} from 'react';
import {isMobile, isMobileOnly} from "mobile-device-detect";
const ApisInfo =  React.lazy(() => import('./ApisInfo'));
const FinMessagesInfo =  React.lazy(() => import('./FinMessagesInfo'));

const InfoGroup =()=>  {
    return (
        <section className='info'>
            <h3>{'Your time is valuable. FINaplo Financial Messages can help you accelerate your ISO20022 project in many ways'}</h3>
            <div className='info__container'>
                <Suspense fallback={<div style={isMobile ? {height:"548px"}:(isMobileOnly ? {height:"1000px"}:{height:"750px"})}/>}>
                    <FinMessagesInfo />
                </Suspense>
                <Suspense fallback={<div style={isMobile ? {height:"556px"}:(isMobileOnly ? {height:"1000px"}:{height:"750px"})}/>}>
                    <ApisInfo />
                </Suspense>
            </div>
        </section>
    );
};

export default InfoGroup;
