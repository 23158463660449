import React from "react";
import {HOMEPAGE_CONTENT} from "../../../lib/constants";

const Benefits =()=>  {

    return (
        <section className='benefits'>
            <h2>{HOMEPAGE_CONTENT.benefits.title}</h2>
            <div className='benefits__container'>
                {HOMEPAGE_CONTENT.benefits.paragraphs.map((paragraph, idx) => {
                    return <article key={'homepage-benefit-' + idx}>
                        <h3>{paragraph.title}</h3>
                        <p>{paragraph.text}</p>
                    </article>
                })}
            </div>
        </section>
    );
};

export default Benefits;