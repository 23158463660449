import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {RESOURCES_CONTENT} from "../../../lib/constants";

const ResourcesPageContentComponent = () =>  {

    return (
        <div className="resources">
            <hgroup>
                <h1 className='page__title'>RESOURCES</h1>
                <h2 className='page__subtitle page__subtitle--resources'>
                    Useful information and tools on how to work with your financial messages
                </h2>
            </hgroup>
            <section className='resources__container'>
                {RESOURCES_CONTENT.map((column, idx)=> {
                    return <div className='resources__column' key={'resources__column--' + idx}>
                        {
                            column.map((section, idx)=> {
                                return <article key={'resource-key-' + idx}>
                                    <h4>
                                        {section.title}
                                    </h4>
                                    {section.links.map((paragraph, idx)=> {
                                        return <p key={'resources-paragraph-' + idx}>
                                            {paragraph.paragraph.map((link, idx)=> {
                                                return link.link ? <a href={link.link}
                                                                      rel='noopener noreferrer nofollow'
                                                                      target='_blank'
                                                                      key={'resource-link-' + idx}>
                                                        {link.name}
                                                    </a>:
                                                    <Link to={link.route}
                                                          key={'resource-route-' + idx}>
                                                        {link.name}
                                                    </Link>
                                            })
                                            }
                                        </p>
                                    })}

                                </article>
                            })
                        }
                    </div>
                })}
            </section>
        </div>
    );
}

export const ResourcesPageContent = withRouter(ResourcesPageContentComponent);
