import React from 'react';

const FintechsLogos =()=>  {

    return (
        <section className="fintechs__logos">
            <article>
                <div className="fintechs__logos--container">
                    <p className="fintechs__logos--content">
                    </p>
                </div>
            </article>
        </section>
    );
};

export default FintechsLogos;
