import React from 'react';
import {ResourcesPageContent} from './ResourcesPageContent';

const ResourcesLazyHelper =(props)=>  {
    return (
        <React.Fragment>
            <ResourcesPageContent />
        </React.Fragment>
    );
};

export default ResourcesLazyHelper;
