import React from 'react';
import Helmet from "react-helmet";

const ThankYou =()=> {

    return (
        <React.Fragment>
            <Helmet>
                <title>{"Thank you | PaymentComponents"}</title>
            </Helmet>
            <section className='thank-you'>
                <article>
                    <p>
                        Thanks for your interest in our products!
                    </p>
                    <p>
                        We just sent you an email with download links and lots of useful information.
                    </p>
                    <p>
                        <i>~The Paymentcomponents team~</i>
                    </p>
                </article>
            </section>
        </React.Fragment>
    );
}

export default ThankYou;
