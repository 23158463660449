export const SIC_EUROSIC_MESSAGES = {
    "camt.029.001.09": {
        "title": "camt.029.001.09",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<Document xmlns=\"http://www.six-interbank-clearing.com/de/camt.029.001.09.ch.01\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"http://www.six-interbank-clearing.com/de/camt.029.001.09.ch.01 camt.029.001.09.ch.01.xsd\">\n" +
            "\t<RsltnOfInvstgtn>\n" +
            "\t\t<Assgnmt>\n" +
            "\t\t\t<Id>R-FOCR-NOK-20230125-2</Id>\n" +
            "\t\t\t<Assgnr>\n" +
            "\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t<Cd>CHSIC</Cd>\n" +
            "\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t<MmbId>092052</MmbId>\n" +
            "\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t\t<Id>SIC</Id>\n" +
            "\t\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</Agt>\n" +
            "\t\t\t</Assgnr>\n" +
            "\t\t\t<Assgne>\n" +
            "\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t<Cd>CHSIC</Cd>\n" +
            "\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t<MmbId>098064</MmbId>\n" +
            "\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</Agt>\n" +
            "\t\t\t</Assgne>\n" +
            "\t\t\t<CreDtTm>2023-01-25T15:20:41</CreDtTm>\n" +
            "\t\t</Assgnmt>\n" +
            "\t\t<Sts>\n" +
            "\t\t\t<Conf>RJCR</Conf>\n" +
            "\t\t</Sts>\n" +
            "\t\t<CxlDtls>\n" +
            "\t\t\t<TxInfAndSts>\n" +
            "\t\t\t\t<CxlStsId>029-4712</CxlStsId>\n" +
            "\t\t\t\t<OrgnlGrpInf>\n" +
            "\t\t\t\t\t<OrgnlMsgId>MSGID-0001</OrgnlMsgId>\n" +
            "\t\t\t\t\t<OrgnlMsgNmId>pacs.008</OrgnlMsgNmId>\n" +
            "\t\t\t\t</OrgnlGrpInf>\n" +
            "\t\t\t\t<OrgnlTxId>2023012500001</OrgnlTxId>\n" +
            "\t\t\t\t<OrgnlUETR>ceca3ff2-1dc4-4164-8cc9-ad52c4c5210d</OrgnlUETR>\n" +
            "\t\t\t\t<TxCxlSts>RJCR</TxCxlSts>\n" +
            "\t\t\t\t<CxlStsRsnInf>\n" +
            "\t\t\t\t\t<Orgtr>\n" +
            "\t\t\t\t\t\t<Id>\n" +
            "\t\t\t\t\t\t\t<OrgId>\n" +
            "\t\t\t\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t\t\t\t<Id>092052</Id>\n" +
            "\t\t\t\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t\t\t\t</OrgId>\n" +
            "\t\t\t\t\t\t</Id>\n" +
            "\t\t\t\t\t</Orgtr>\n" +
            "\t\t\t\t\t<Rsn>\n" +
            "\t\t\t\t\t\t<Cd>CUST</Cd>\n" +
            "\t\t\t\t\t</Rsn>\n" +
            "\t\t\t\t\t<AddtlInf>ATR7 CXLID-Camt056CancelIdRef4711</AddtlInf>\n" +
            "\t\t\t\t</CxlStsRsnInf>\n" +
            "\t\t\t</TxInfAndSts>\n" +
            "\t\t</CxlDtls>\n" +
            "\t</RsltnOfInvstgtn>\n" +
            "</Document>\n"
    },
    "camt.056.001.08": {
        "title": "camt.056.001.08",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<Document xmlns=\"http://www.six-interbank-clearing.com/de/camt.056.001.08.ch.02\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"http://www.six-interbank-clearing.com/de/camt.056.001.08.ch.02 camt.056.001.08.ch.02.xsd\">\n" +
            "\t<FIToFIPmtCxlReq>\n" +
            "\t\t<Assgnmt>\n" +
            "\t\t\t<Id>MSGID-camt056-20230522-1</Id>\n" +
            "\t\t\t<Assgnr>\n" +
            "\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t<Cd>CHSIC</Cd>\n" +
            "\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t<MmbId>098064</MmbId>\n" +
            "\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t\t<Id>SIC</Id>\n" +
            "\t\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</Agt>\n" +
            "\t\t\t</Assgnr>\n" +
            "\t\t\t<Assgne>\n" +
            "\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t<Cd>CHSIC</Cd>\n" +
            "\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t<MmbId>092052</MmbId>\n" +
            "\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</Agt>\n" +
            "\t\t\t</Assgne>\n" +
            "\t\t\t<CreDtTm>2023-05-22T15:10:10</CreDtTm>\n" +
            "\t\t</Assgnmt>\n" +
            "\t\t<Undrlyg>\n" +
            "\t\t\t<TxInf>\n" +
            "\t\t\t\t<CxlId>CXLID-Camt056CancelIdRef4711</CxlId>\n" +
            "\t\t\t\t<OrgnlGrpInf>\n" +
            "\t\t\t\t\t<OrgnlMsgId>MSGID-pacs008-20230522-0001</OrgnlMsgId>\n" +
            "\t\t\t\t\t<OrgnlMsgNmId>pacs.008</OrgnlMsgNmId>\n" +
            "\t\t\t\t</OrgnlGrpInf>\n" +
            "\t\t\t\t<OrgnlTxId>20230522-1-0001</OrgnlTxId>\n" +
            "\t\t\t\t<OrgnlUETR>eb6305c9-1f7f-49de-aed0-16487c27b42d</OrgnlUETR>\n" +
            "\t\t\t\t<OrgnlIntrBkSttlmAmt Ccy=\"CHF\">1111.00</OrgnlIntrBkSttlmAmt>\n" +
            "\t\t\t\t<OrgnlIntrBkSttlmDt>2023-05-22</OrgnlIntrBkSttlmDt>\n" +
            "\t\t\t\t<CxlRsnInf>\n" +
            "\t\t\t\t\t<Orgtr>\n" +
            "\t\t\t\t\t\t<Nm>Uhrengrosshandel Buxtehude</Nm>\n" +
            "\t\t\t\t\t</Orgtr>\n" +
            "\t\t\t\t\t<Rsn>\n" +
            "\t\t\t\t\t\t<Cd>CUST</Cd>\n" +
            "\t\t\t\t\t</Rsn>\n" +
            "\t\t\t\t</CxlRsnInf>\n" +
            "\t\t\t</TxInf>\n" +
            "\t\t</Undrlyg>\n" +
            "\t</FIToFIPmtCxlReq>\n" +
            "</Document>\n"
    },
    "pacs.002.001.10": {
        "title": "pacs.002.001.10",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<Document xmlns=\"http://www.six-interbank-clearing.com/de/pacs.002.001.10.ch.02\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"http://www.six-interbank-clearing.com/de/pacs.002.001.10.ch.02 pacs.002.001.10.ch.02.xsd\">\n" +
            "\t<FIToFIPmtStsRpt>\n" +
            "\t\t<GrpHdr>\n" +
            "\t\t\t<MsgId>SIC12349878</MsgId>\n" +
            "\t\t\t<CreDtTm>2022-12-17T09:30:47</CreDtTm>\n" +
            "\t\t</GrpHdr>\n" +
            "\t\t<TxInfAndSts>\n" +
            "\t\t\t<OrgnlGrpInf>\n" +
            "\t\t\t\t<OrgnlMsgId>UNKNOWN</OrgnlMsgId>\n" +
            "\t\t\t\t<OrgnlMsgNmId>UNKNOWN</OrgnlMsgNmId>\n" +
            "\t\t\t</OrgnlGrpInf>\n" +
            "\t\t\t<OrgnlTxId>UNKNOWN</OrgnlTxId>\n" +
            "\t\t\t<TxSts>RJCT</TxSts>\n" +
            "\t\t\t<StsRsnInf>\n" +
            "\t\t\t\t<Orgtr>\n" +
            "\t\t\t\t\t<Id>\n" +
            "\t\t\t\t\t\t<OrgId>\n" +
            "\t\t\t\t\t\t\t<AnyBIC>TKZHCHZZXXX</AnyBIC>\n" +
            "\t\t\t\t\t\t</OrgId>\n" +
            "\t\t\t\t\t</Id>\n" +
            "\t\t\t\t</Orgtr>\n" +
            "\t\t\t\t<Rsn>\n" +
            "\t\t\t\t\t<Prtry>118</Prtry>\n" +
            "\t\t\t\t</Rsn>\n" +
            "\t\t\t\t<AddtlInf><![CDATA[>XML<?xml version=\"1.0\" encoding=\"UTF-8\"?><Document xsi:schemaLocation=\"http://www.six-interbank-cleari]]></AddtlInf>\n" +
            "\t\t\t\t<AddtlInf><![CDATA[ng.com/de/pacs.008.001.08.ch.02 pacs.008.001.08.ch.02.xsd\"  xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-i]]></AddtlInf>\n" +
            "\t\t\t\t<AddtlInf><![CDATA[nstance\"><FIToFICstmrCdtTrf><GrpHdr><MsgId>013697O4Q9OEQP2S'</MsgId><CreDtTm>2022-]]></AddtlInf>\n" +
            "\t\t\t\t<AddtlInf><![CDATA[12-17T09:30:40</CreDtTm><NbOfTxs>1</NbOfTxs><IntrBkSttlmDt>2014-07-30</IntrBkSttlmDt>]]></AddtlInf>\n" +
            "\t\t\t\t<AddtlInf><![CDATA[<SttlmInf><SttlmMtd>CLRG</SttlmMtd></SttlmInf><InstgAgt><FinInstnId>]]></AddtlInf>\n" +
            "\t\t\t\t<AddtlInf><![CDATA[<ClrSysMmbId><ClrSysId><Cd>CHSIC</Cd></ClrSysId>]]></AddtlInf>\n" +
            "\t\t\t\t<AddtlInf><![CDATA[t><FinInstnId><ClrSysMmbId><ClrSysId><Cd>CHSIC</Cd>]]></AddtlInf>                                   \n" +
            "\t\t\t\t<AddtlInf><![CDATA[<MmbId>091001</MmbId></ClrSysMmbId></FinInstnId></InstgAgt><InstdAg]]></AddtlInf>\n" +
            "\t\t\t\t<AddtlInf><![CDATA[t><FinInstnId><ClrSysMmbId><ClrSysId><Cd>CHSIC</Cd>]]></AddtlInf>\n" +
            "\t\t\t\t<AddtlInf><![CDATA[</ClrSysId><MmbId>099150</MmbId></ClrSysMmbId></FinInstnId>]]></AddtlInf>\n" +
            "\t\t\t</StsRsnInf>\n" +
            "\t\t\t<AccptncDtTm>2022-12-17T09:30:46Z</AccptncDtTm>\n" +
            "\t\t\t<InstgAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>CHSIC</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>UNKNOWN</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstgAgt>\n" +
            "\t\t\t<InstdAgt>\n" +
            "\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>CHSIC</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>UNKNOWN</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstdAgt>\n" +
            "\t\t\t<OrgnlTxRef>\n" +
            "\t\t\t\t<SttlmInf>\n" +
            "\t\t\t\t\t<SttlmMtd>CLRG</SttlmMtd>\n" +
            "\t\t\t\t\t<ClrSys>\n" +
            "\t\t\t\t\t\t<Cd>SIC</Cd>\n" +
            "\t\t\t\t\t</ClrSys>\n" +
            "\t\t\t\t</SttlmInf>\n" +
            "\t\t\t</OrgnlTxRef>\n" +
            "\t\t</TxInfAndSts>\n" +
            "\t</FIToFIPmtStsRpt>\n" +
            "</Document>\n"
    },
    "pacs.004.001.09": {
        "title": "pacs.004.001.09",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<Document xmlns=\"http://www.six-interbank-clearing.com/de/pacs.004.001.09.ch.02\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"http://www.six-interbank-clearing.com/de/pacs.004.001.09.ch.02 pacs.004.001.09.ch.02.xsd\">\n" +
            "\t<PmtRtr>\n" +
            "\t\t<GrpHdr>\n" +
            "\t\t\t<MsgId>MSGID-pacs004-NARR-20230522-0001</MsgId>\n" +
            "\t\t\t<CreDtTm>2023-05-22T13:20:41</CreDtTm>\n" +
            "\t\t\t<NbOfTxs>1</NbOfTxs>\n" +
            "\t\t\t<TtlRtrdIntrBkSttlmAmt Ccy=\"CHF\">1111.00</TtlRtrdIntrBkSttlmAmt>\n" +
            "\t\t\t<SttlmInf>\n" +
            "\t\t\t\t<SttlmMtd>CLRG</SttlmMtd>\n" +
            "\t\t\t\t<ClrSys>\n" +
            "\t\t\t\t\t<Cd>SIC</Cd>\n" +
            "\t\t\t\t</ClrSys>\n" +
            "\t\t\t</SttlmInf>\n" +
            "\t\t</GrpHdr>\n" +
            "\t\t<TxInf>\n" +
            "\t\t\t<RtrId>1</RtrId>\n" +
            "\t\t\t<OrgnlGrpInf>\n" +
            "\t\t\t\t<OrgnlMsgId>MSGID-pacs008-20230122-0001</OrgnlMsgId>\n" +
            "\t\t\t\t<OrgnlMsgNmId>pacs.008</OrgnlMsgNmId>\n" +
            "\t\t\t</OrgnlGrpInf>\n" +
            "\t\t\t<OrgnlTxId>20230522-1-0001</OrgnlTxId>\n" +
            "\t\t\t<OrgnlUETR>eb6305c9-1f7f-49de-aed0-16487c27b42d</OrgnlUETR>\n" +
            "\t\t\t<OrgnlIntrBkSttlmAmt Ccy=\"CHF\">1111.00</OrgnlIntrBkSttlmAmt>\n" +
            "\t\t\t<OrgnlIntrBkSttlmDt>2023-01-22</OrgnlIntrBkSttlmDt>\n" +
            "\t\t\t<RtrdIntrBkSttlmAmt Ccy=\"CHF\">1111.00</RtrdIntrBkSttlmAmt>\n" +
            "\t\t\t<IntrBkSttlmDt>2023-05-22</IntrBkSttlmDt>\n" +
            "\t\t\t<ClrSysRef>CSTRTN</ClrSysRef>\n" +
            "\t\t\t<InstgAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>CHSIC</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>092052</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstgAgt>\n" +
            "\t\t\t<InstdAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>CHSIC</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>098064</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstdAgt>\n" +
            "\t\t\t<RtrChain>\n" +
            "\t\t\t\t<Dbtr>\n" +
            "\t\t\t\t\t<Pty>\n" +
            "\t\t\t\t\t\t<Nm>Horlogerie du Joux</Nm>\n" +
            "\t\t\t\t\t\t<PstlAdr>\n" +
            "\t\t\t\t\t\t\t<PstCd>1111</PstCd>\n" +
            "\t\t\t\t\t\t\t<TwnNm>Les Huits</TwnNm>\n" +
            "\t\t\t\t\t\t\t<Ctry>CH</Ctry>\n" +
            "\t\t\t\t\t\t</PstlAdr>\n" +
            "\t\t\t\t\t</Pty>\n" +
            "\t\t\t\t</Dbtr>\n" +
            "\t\t\t\t<Cdtr>\n" +
            "\t\t\t\t\t<Pty>\n" +
            "\t\t\t\t\t\t<Nm>Uhrengrosshandel Buxtehude</Nm>\n" +
            "\t\t\t\t\t\t<PstlAdr>\n" +
            "\t\t\t\t\t\t\t<PstCd>9999</PstCd>\n" +
            "\t\t\t\t\t\t\t<TwnNm>Buxtehude</TwnNm>\n" +
            "\t\t\t\t\t\t\t<Ctry>CH</Ctry>\n" +
            "\t\t\t\t\t\t</PstlAdr>\n" +
            "\t\t\t\t\t</Pty>\n" +
            "\t\t\t\t</Cdtr>\n" +
            "\t\t\t</RtrChain>\n" +
            "\t\t\t<RtrRsnInf>\n" +
            "\t\t\t\t<Orgtr>\n" +
            "\t\t\t\t\t<Nm>Banque Commerciale</Nm>\n" +
            "\t\t\t\t</Orgtr>\n" +
            "\t\t\t\t<Rsn>\n" +
            "\t\t\t\t\t<Cd>NARR</Cd>\n" +
            "\t\t\t\t</Rsn>\n" +
            "\t\t\t\t<AddtlInf>RETOUR pacs.008 VAL 22.01.2023 KONTO UNBEKANNT</AddtlInf>\n" +
            "\t\t\t</RtrRsnInf>\n" +
            "\t\t</TxInf>\n" +
            "\t</PmtRtr>\n" +
            "</Document>\n"
    },
    "pacs.008.001.08": {
        "title": "pacs.008.001.08",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<Document xmlns=\"http://www.six-interbank-clearing.com/de/pacs.008.001.08.ch.02\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"http://www.six-interbank-clearing.com/de/pacs.008.001.08.ch.02 pacs.008.001.08.ch.02.xsd\">\n" +
            "\t<FIToFICstmrCdtTrf>\n" +
            "\t\t<GrpHdr>\n" +
            "\t\t\t<MsgId>MSGID-pacs008-20230122-0001</MsgId>\n" +
            "\t\t\t<CreDtTm>2023-01-22T09:30:47</CreDtTm>\n" +
            "\t\t\t<NbOfTxs>1</NbOfTxs>\n" +
            "\t\t\t<SttlmInf>\n" +
            "\t\t\t\t<SttlmMtd>CLRG</SttlmMtd>\n" +
            "\t\t\t\t<ClrSys>\n" +
            "\t\t\t\t\t<Cd>SIC</Cd>\n" +
            "\t\t\t\t</ClrSys>\n" +
            "\t\t\t</SttlmInf>\n" +
            "\t\t</GrpHdr>\n" +
            "\t\t<CdtTrfTxInf>\n" +
            "\t\t\t<PmtId>\n" +
            "\t\t\t\t<EndToEndId>NOTPROVIDED</EndToEndId>\n" +
            "\t\t\t\t<TxId>20230122-1-0001</TxId>\n" +
            "\t\t\t\t<UETR>eb6305c9-1f7f-49de-aed0-16487c27b42d</UETR>\n" +
            "\t\t\t</PmtId>\n" +
            "\t\t\t<PmtTpInf>\n" +
            "\t\t\t\t<LclInstrm>\n" +
            "\t\t\t\t\t<Prtry>CSTPMT</Prtry>\n" +
            "\t\t\t\t</LclInstrm>\n" +
            "\t\t\t</PmtTpInf>\n" +
            "\t\t\t<IntrBkSttlmAmt Ccy=\"CHF\">1111</IntrBkSttlmAmt>\n" +
            "\t\t\t<IntrBkSttlmDt>2023-11-22</IntrBkSttlmDt>\n" +
            "\t\t\t<ChrgBr>SHAR</ChrgBr>\n" +
            "\t\t\t<InstgAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>CHSIC</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>098064</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstgAgt>\n" +
            "\t\t\t<InstdAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>CHSIC</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>092052</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstdAgt>\n" +
            "\t\t\t<Dbtr>\n" +
            "\t\t\t\t<Nm>Uhrengrosshandel Müller</Nm>\n" +
            "\t\t\t\t<PstlAdr>\n" +
            "\t\t\t\t\t<PstCd>9999</PstCd>\n" +
            "\t\t\t\t\t<TwnNm>Gänswil</TwnNm>\n" +
            "\t\t\t\t\t<Ctry>CH</Ctry>\n" +
            "\t\t\t\t</PstlAdr>\n" +
            "\t\t\t</Dbtr>\n" +
            "\t\t\t<DbtrAcct>\n" +
            "\t\t\t\t<Id>\n" +
            "\t\t\t\t\t<IBAN>CH4089999001234567890</IBAN>\n" +
            "\t\t\t\t</Id>\n" +
            "\t\t\t</DbtrAcct>\n" +
            "\t\t\t<DbtrAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>CHSIC</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>098064</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</DbtrAgt>\n" +
            "\t\t\t<CdtrAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>CHSIC</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>092052</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</CdtrAgt>\n" +
            "\t\t\t<Cdtr>\n" +
            "\t\t\t\t<Nm>Horlogerie du Joux</Nm>\n" +
            "\t\t\t\t<PstlAdr>\n" +
            "\t\t\t\t\t<PstCd>1111</PstCd>\n" +
            "\t\t\t\t\t<TwnNm>Les Huits</TwnNm>\n" +
            "\t\t\t\t\t<Ctry>CH</Ctry>\n" +
            "\t\t\t\t</PstlAdr>\n" +
            "\t\t\t</Cdtr>\n" +
            "\t\t\t<CdtrAcct>\n" +
            "\t\t\t\t<Id>\n" +
            "\t\t\t\t\t<IBAN>CH1708841000987654321</IBAN>\n" +
            "\t\t\t\t</Id>\n" +
            "\t\t\t</CdtrAcct>\n" +
            "\t\t</CdtTrfTxInf>\n" +
            "\t</FIToFICstmrCdtTrf>\n" +
            "</Document>\n"
    }
}
