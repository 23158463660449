export const TRANSLATOR_CBPR_MT_MESSAGES = {
    "103": {
        "title": "MT103",
        "conversion": "pacs.008",
        "msg":
            `{1:F01AAAAGRA0AXXX0057000289}{2:O1030919010321BBBBGRA0AXXX00570001710103210920N}{3:{108:MT103 003 OF 045}{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:5387354
:13C:/SNDTIME/1249+0200
:23B:CRED
:23E:SDVA
:26T:K90
:32A:000526USD1101,50
:33B:EUR1121,50
:36:0,0094
:50A:/ACCOUNTID
TESTBICA
:52A:BKAUATWW
:53A:BKAUATWW
:54A:TESTBICB
:55A:TESTBICC
:56A:TESTBICD
:57A:TESTBICE
:59:/723491524
C. KLEIN
BLOEMENGRACHT 15
AMSTERDAM
:70:/RFB/BET072
:71A:BEN
:71F:USD10,
:71F:USD11,2
:72:/INS/CHASUS33
/CLSTIME/1334+0200
:77B:/ORDERRES/BE//MEILAAN 1, 9000 GENT
//SOME EXTRA INFO HERE
//AND SOME OTHER HERE
-}{5:{MAC:75D138E4}{CHK:DE1B0D71FA96}}`
    },
    "103stp": {
        "title": "MT103 STP",
        "conversion": "pacs.008",
        "msg": `{1:F01AAAAGRA0AXXX0057000289}{2:O1030919010321BBBBGRA0AXXX00570001710103210920N}{3:{119:STP}{108:MT103 003 OF 045}{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:5387354
:13C:/SNDTIME/1249+0200
:23B:CRED
:23E:SDVA
:26T:K90
:32A:000526USD1101,50
:33B:EUR1121,50
:36:0,0094
:50A:/ACCOUNTID
TESTBICA
:52A:BKAUATWW
:53A:BKAUATWW
:54A:TESTBICB
:55A:TESTBICC
:56A:TESTBICD
:57A:TESTBICE
:59:/723491524
C. KLEIN
BLOEMENGRACHT 15
AMSTERDAM
:70:/RFB/BET072
:71A:BEN
:71F:USD10,
:71F:USD11,2
:72:/INS/CHASUS33
/CLSTIME/1334+0200
:77B:/ORDERRES/BE//MEILAAN 1, 9000 GENT
//SOME EXTRA INFO HERE
//AND SOME OTHER HERE
-}{5:{MAC:75D138E4}{CHK:DE1B0D71FA96}}`
    },
    "103return": {
        "title": "MT103 (Return)",
        "conversion": "pacs.004",
        "msg": `{1:F01AAAAGRA0AXXX0057000289}{2:O1030919010321BBBBGRA0AXXX00570001710103210920N}{3:{108:MT103 003 OF 045}{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:5387354
:13C:/SNDTIME/1249+0200
:23B:CRED
:23E:SDVA
:26T:K90
:32A:000526USD1101,50
:33B:EUR1121,50
:36:0,0094
:50A:/ACCOUNTID
TESTBICA
:52A:BKAUATWW
:53A:BKAUATWW
:54A:TESTBICB
:55A:TESTBICC
:56A:TESTBICD
:57A:TESTBICE
:59:/723491524
C. KLEIN
BLOEMENGRACHT 15
AMSTERDAM
:70:/RFB/BET072
:71A:BEN
:71F:USD10,
:71F:USD11,2
:72:/RETN/32
/AC01/
/MREF/123456789
:77B:/ORDERRES/BE//MEILAAN 1, 9000 GENT
//SOME EXTRA INFO HERE
//AND SOME OTHER HERE
-}{5:{MAC:75D138E4}{CHK:DE1B0D71FA96}}`
    },
    "192": {
        "title": "MT192",
        "conversion": "camt.056",
        "msg": `{1:F21COPZBEB0AXXX0377002853}{4:{177:1108050226}{451:0}}{1:F01COPZBEB0AXXX0377002853}{2:O1921519110804LRLRXXXX4A1100009044761108041720U}{3:{108:MT192 006 OF 014}{121:daf8e426-26c9-4fdb-b37e-767b8179050d}}{4:
:20:00245
:21:1900650BET
:11S:103
000103
:79:/AGNT/Incorrect Agent
:32A:001226EUR1101,50
-}{5:{MAC:00000000}{CHK:217F2A63931D}{TNG:}}{S:{SAC:}{COP:P}}`
    },
    "196": {
        "title": "MT196",
        "conversion": "camt.029",
        "msg": `{1:F21COPZBEB0AXXX0377002674}{4:{177:1108050220}{451:0}}{1:F01COPZBEB0AXXX0377002674}{2:O1961513110804LRLRXXXX4A1100009042971108041714N}{3:{108:MT196 005 OF 020}{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:00283
:21:123456/DEV
:76:/CNCL/VALUE DATE SHOULD BE 961217
:11R:103
000105
0538026593
-}{5:{MAC:00000000}{CHK:337A7A10B9D6}{TNG:}}{S:{SAC:}{COP:P}}`
    },
    "202": {
        "title": "MT202",
        "conversion": "pacs.009",
        "msg": `{1:F01AAAABEBBAXXX0000000000}{2:I202CCCCUS33AXXXN}{3:{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:987
:21:090525/123COV
:13C:/SNDTIME/1249+0200
:32A:090527USD10500,00
:52A:BKAUATWW
:56A:TESTBICD
:57A:TESTBICE
:58A:TESTBICF
:72:/INS/CHASUS33
-}{5:{MAC:75D138E4}{CHK:DE1B0D71FA96}}`
    },
    "202cov": {
        "title": "MT202 COV",
        "conversion": "pacs.009",
        "msg": `{1:F01AAAABEBBAXXX0000000000}{2:I202CCCCUS33AXXXN}{3:{119:COV}{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:987COV
:21:090525/123COV
:32A:090527USD10500,00
:58A:BBBBGB22
:50F:/123564982101
1/MR. BIG
2/HIGH STREET 3
3/BE/BRUSSELS
:59:/987654321
MR. SMALL
LOW STREET 15
LONDON GB
-}{5:{MAC:75D138E4}{CHK:DE1B0D71FA96}}`
    },
    "202return": {
        "title": "MT202 (Return)",
        "conversion": "pacs.004",
        "msg": `{1:F01AAAABEBBAXXX0000000000}{2:I202CCCCUS33AXXXN}{3:{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:987
:21:090525/123COV
:32A:090527USD10500,00
:58A:BBBBGB22
:72:/RETN/32
/AC01/
/MREF/123456789
-}{5:{MAC:75D138E4}{CHK:DE1B0D71FA96}}`
    },
    "202adv": {
        "title": "MT202 ADV",
        "conversion": "pacs.009",
        "msg": `{1:F21COPZBEB0AXXX0377001874}{4:{177:1108050153}{451:0}}{1:F01COPZBEB0AXXX0377001874}{2:O2021447110804LRLRXXXX4A1100009034971108041647N}{3:{108:MT202 001 OF 076}{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:00344
:21:8861198-0706
:32A:021008USD5443,99
:53A://AU123456
TESTBICA
:57A:TSTBUSVTVTB
:58A:TESTFRVT
-}{5:{MAC:00000000}{CHK:01FE23B6AC56}{TNG:}}{S:{SAC:}{COP:P}}`
    },
    "205": {
        "title": "MT205",
        "conversion": "pacs.009",
        "msg": `{1:F21COPZBEB0AXXX0377002682}{4:{177:1108050220}{451:0}}{1:F01COPZBEB0AXXX0377002682}{2:O2051514110804LRLRXXXX4A1100009043051108041714N}{3:{108:MT205 005 OF 020}{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:00441
:21:123456/DEV
:32A:091231EUR77659,
:52A:TESTDEVT
:57D:HOLLANDSE BANK UNIE N.V.
AMSTERDAM
:58A:TESTUSVT
-}{5:{MAC:00000000}{CHK:16D20AD6A9ED}{TNG:}}{S:{SAC:}{COP:P}}`
    },
    "205cov": {
        "title": "MT205 COV",
        "conversion": "pacs.009",
        "msg": `{1:F01AAAABEBBAXXX0000000000}{2:I205CCCCUS33AXXXN}{3:{119:COV}{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:987COV
:21:090525/123COV
:32A:090527USD10500,00
:52A://AU123456
AAAABEBBXXX
:58A:BBBBGB22
:50F:/123564982101
1/MR. BIG
2/HIGH STREET 3
3/BE/BRUSSELS
:59:/987654321
MR. SMALL
LOW STREET 15
LONDON GB
-}{5:{MAC:75D138E4}{CHK:DE1B0D71FA96}}`
    },
    "205return": {
        "title": "MT205 (Return)",
        "conversion": "pacs.004",
        "msg": `{1:F01AAAABEBBAXXX0000000000}{2:I205CCCCUS33AXXXN}{3:{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:987
:21:090525/123COV
:32A:090527USD10500,00
:52A://AU123456
AAAABEBBXXX
:58A:BBBBGB22
:72:/RETN/32
/AC01/
/MREF/123456789
-}{5:{MAC:75D138E4}{CHK:DE1B0D71FA96}}
`
    },
    "296": {
        "title": "MT296",
        "conversion": "camt.029",
        "msg": `{1:F21COPZBEB0AXXX0377002690}{4:{177:1108050220}{451:0}}{1:F01COPZBEB0AXXX0377002690}{2:O2961514110804LRLRXXXX4A1100009043131108041714N}{3:{108:MT296 005 OF 016}{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:00549
:21:123456/DEV
:76:/CNCL/VALUE DATE SHOULD BE 961217
:11S:202
000103
-}{5:{MAC:00000000}{CHK:12E314685D38}{TNG:}}{S:{SAC:}{COP:P}}`
    },
    "900": {
        "title": "MT900",
        "conversion": "camt.054",
        "msg": `{1:F21COPZBEB0AXXX0377002827}{4:{177:1108050226}{451:0}}{1:F01COPZBEB0AXXX0377002827}{2:O9001518110804LRLRXXXX4A1100009044501108041719N}{3:{108:MT910 005 OF 034}}{4:
:20:02569
:21:123456/DEV
:25:6-9412771
:13D:1401081515+1300
:32A:000103USD112,
:52A:/1/ACCOUNTID1
TESTSEVT
:72:/INS/CHASUS33
-}{5:{CHK:387E432DD9B0}{TNG:}}{S:{COP:P}}`
    },
    "910": {
        "title": "MT910",
        "conversion": "camt.054",
        "msg": `{1:F21COPZBEB0AXXX0377002827}{4:{177:1108050226}{451:0}}{1:F01COPZBEB0AXXX0377002827}{2:O9101518110804LRLRXXXX4A1100009044501108041719N}{3:{108:MT910 005 OF 034}}{4:
:20:02569
:21:123456/DEV
:25:6-9412771
:13D:1401081515+1300
:32A:000103USD112,
:50A:/ACCOUNTID
TESTBICA
:52A:/1/ACCOUNTID1
TESTSEVT
:56A:/1/ACCOUNTID1
TESTBICD
:72:/INS/CHASUS33
-}{5:{CHK:387E432DD9B0}{TNG:}}{S:{COP:P}}`
    }
}