import React from 'react';

function SwaggerLoader() {
    return (
        <div className='dot-loader__wrapper'>
            <h3>Loading Swagger</h3>
            <div className='dot-loader'>
                <span className='dot-loader__bracket  dot-loader__bracket--left'>{'{'}</span>
                <div className="dot-loader__dots">
                    <div className="dot-loader__item dot1"></div>
                    <div className="dot-loader__item dot2"></div>
                    <div className="dot-loader__item dot3"></div>
                </div>
                <span className='dot-loader__bracket dot-loader__bracket--right'>{'}'}</span>
            </div>
        </div>
    );
}

export default SwaggerLoader;
