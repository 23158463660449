import React, {Suspense, useEffect, useState} from "react";
import SwaggerLoader from "../../uiElements/SwaggerLoader";
import ErrorAnimation from "../../uiElements/ErrorAnimation";
import {isMobile} from "mobile-device-detect";


const ApiSwagger =(props)=>  {
    const {swagData, dataFailure} = props;

    const [swag, setSwag] = useState(null);
    const [fail, setFail] = useState(null);

    const SwaggerUI =  !isMobile ? React.lazy(() => import('swagger-ui-react')) : null;

    useEffect(()=> {
        setSwag(swagData)
    },[swagData]);

    useEffect(()=> {
        setFail(dataFailure)
    },[dataFailure]);

    return (
        <section className='checker-api__swagger'>
            <article>
                {swag && !isMobile ?
                    <Suspense fallback={<div style={{height:"100%"}}/>}>
                        <SwaggerUI spec={swag} />
                    </Suspense>
                    :
                    <React.Fragment>
                        { !fail ?
                            <SwaggerLoader />
                            :
                            <div className='checker-api__swag-fail'>
                                <ErrorAnimation />
                                <span>
                                    {"An error occurred while fetching Swagger data."}
                                </span>
                                <span>
                                    {"Please try again later."}
                                </span>
                            </div>
                        }
                    </React.Fragment>
                }
            </article>
        </section>
    );
};

export default ApiSwagger;